import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

const Address = () => import('@/entities/address/address.vue');
const AddressUpdate = () => import('@/entities/address/address-update.vue');
const AddressDetails = () => import('@/entities/address/address-details.vue');

const Attendance = () => import('@/entities/attendance/attendance.vue');
const AttendanceUpdate = () => import('@/entities/attendance/attendance-update.vue');
const AttendanceDetails = () => import('@/entities/attendance/attendance-details.vue');

const DeletedAttendance = () => import('@/entities/deleted-attendance/deleted-attendance.vue');
const DeletedAttendanceUpdate = () => import('@/entities/deleted-attendance/deleted-attendance-update.vue');
const DeletedAttendanceDetails = () => import('@/entities/deleted-attendance/deleted-attendance-details.vue');

const Center = () => import('@/entities/center/center.vue');
const CenterUpdate = () => import('@/entities/center/center-update.vue');
const CenterDetails = () => import('@/entities/center/center-details.vue');

const CenterGroup = () => import('@/entities/center-group/center-group.vue');
const CenterGroupUpdate = () => import('@/entities/center-group/center-group-update.vue');
const CenterGroupDetails = () => import('@/entities/center-group/center-group-details.vue');

const CenterRequestToken = () => import('@/entities/center-request-token/center-request-token.vue');
const CenterRequestTokenUpdate = () => import('@/entities/center-request-token/center-request-token-update.vue');
const CenterRequestTokenDetails = () => import('@/entities/center-request-token/center-request-token-details.vue');

const CenterToken = () => import('@/entities/center-token/center-token.vue');
const CenterTokenUpdate = () => import('@/entities/center-token/center-token-update.vue');
const CenterTokenDetails = () => import('@/entities/center-token/center-token-details.vue');

const Company = () => import('@/entities/company/company.vue');
const CompanyUpdate = () => import('@/entities/company/company-update.vue');
const CompanyDetails = () => import('@/entities/company/company-details.vue');

const Country = () => import('@/entities/country/country.vue');
const CountryUpdate = () => import('@/entities/country/country-update.vue');
const CountryDetails = () => import('@/entities/country/country-details.vue');

const DeletedMember = () => import('@/entities/deleted-member/deleted-member.vue');
const DeletedMemberUpdate = () => import('@/entities/deleted-member/deleted-member-update.vue');
const DeletedMemberDetails = () => import('@/entities/deleted-member/deleted-member-details.vue');

const Employee = () => import('@/entities/employee/employee.vue');
const EmployeeUpdate = () => import('@/entities/employee/employee-update.vue');
const EmployeeDetails = () => import('@/entities/employee/employee-details.vue');

const EmployeeHistory = () => import('@/entities/employee-history/employee-history.vue');
const EmployeeHistoryUpdate = () => import('@/entities/employee-history/employee-history-update.vue');
const EmployeeHistoryDetails = () => import('@/entities/employee-history/employee-history-details.vue');

const FileToken = () => import('@/entities/file-token/file-token.vue');
const FileTokenUpdate = () => import('@/entities/file-token/file-token-update.vue');
const FileTokenDetails = () => import('@/entities/file-token/file-token-details.vue');

const Helper = () => import('@/entities/helper/helper.vue');
const HelperUpdate = () => import('@/entities/helper/helper-update.vue');
const HelperDetails = () => import('@/entities/helper/helper-details.vue');

const HelperHistory = () => import('@/entities/helper-history/helper-history.vue');
const HelperHistoryUpdate = () => import('@/entities/helper-history/helper-history-update.vue');
const HelperHistoryDetails = () => import('@/entities/helper-history/helper-history-details.vue');

const Level = () => import('@/entities/level/level.vue');
const LevelUpdate = () => import('@/entities/level/level-update.vue');
const LevelDetails = () => import('@/entities/level/level-details.vue');

const LevelProgress = () => import('@/entities/level-progress/level-progress.vue');
const LevelProgressUpdate = () => import('@/entities/level-progress/level-progress-update.vue');
const LevelProgressDetails = () => import('@/entities/level-progress/level-progress-details.vue');

const DeletedLevelProgress = () => import('@/entities/deleted-level-progress/deleted-level-progress.vue');
const DeletedLevelProgressUpdate = () => import('@/entities/deleted-level-progress/deleted-level-progress-update.vue');
const DeletedLevelProgressDetails = () => import('@/entities/deleted-level-progress/deleted-level-progress-details.vue');

const Member = () => import('@/entities/member/member.vue');
const MemberUpdate = () => import('@/entities/member/member-update.vue');
const MemberDetails = () => import('@/entities/member/member-details.vue');

const MemberInformation = () => import('@/entities/member-information/member-information.vue');
const MemberInformationUpdate = () => import('@/entities/member-information/member-information-update.vue');
const MemberInformationDetails = () => import('@/entities/member-information/member-information-details.vue');

const MemberCareer = () => import('@/entities/member-career/member-career.vue');
const MemberCareerUpdate = () => import('@/entities/member-career/member-career-update.vue');
const MemberCareerDetails = () => import('@/entities/member-career/member-career-details.vue');

const MemberHistory = () => import('@/entities/member-history/member-history.vue');
const MemberHistoryUpdate = () => import('@/entities/member-history/member-history-update.vue');
const MemberHistoryDetails = () => import('@/entities/member-history/member-history-details.vue');

const MemberLogging = () => import('@/entities/member-logging/member-logging.vue');
const MemberLoggingUpdate = () => import('@/entities/member-logging/member-logging-update.vue');
const MemberLoggingDetails = () => import('@/entities/member-logging/member-logging-details.vue');

const MemberRelation = () => import('@/entities/member-relation/member-relation.vue');
const MemberRelationUpdate = () => import('@/entities/member-relation/member-relation-update.vue');
const MemberRelationDetails = () => import('@/entities/member-relation/member-relation-details.vue');

const MemberSchedule = () => import('@/entities/member-schedule/member-schedule.vue');
const MemberScheduleUpdate = () => import('@/entities/member-schedule/member-schedule-update.vue');
const MemberScheduleDetails = () => import('@/entities/member-schedule/member-schedule-details.vue');

const MemberVehicle = () => import('@/entities/member-vehicle/member-vehicle.vue');
const MemberVehicleUpdate = () => import('@/entities/member-vehicle/member-vehicle-update.vue');
const MemberVehicleDetails = () => import('@/entities/member-vehicle/member-vehicle-details.vue');

const Notification = () => import('@/entities/notification/notification.vue');
const NotificationUpdate = () => import('@/entities/notification/notification-update.vue');
const NotificationDetails = () => import('@/entities/notification/notification-details.vue');

const Organization = () => import('@/entities/organization/organization.vue');
const OrganizationUpdate = () => import('@/entities/organization/organization-update.vue');
const OrganizationDetails = () => import('@/entities/organization/organization-details.vue');

const PermissionItem = () => import('@/entities/permission-item/permission-item.vue');
const PermissionItemUpdate = () => import('@/entities/permission-item/permission-item-update.vue');
const PermissionItemDetails = () => import('@/entities/permission-item/permission-item-details.vue');

const PermissionProfile = () => import('@/entities/permission-profile/permission-profile.vue');
const PermissionProfileUpdate = () => import('@/entities/permission-profile/permission-profile-update.vue');
const PermissionProfileDetails = () => import('@/entities/permission-profile/permission-profile-details.vue');

const PermissionProfileItemMap = () => import('@/entities/permission-profile-item-map/permission-profile-item-map.vue');
const PermissionProfileItemMapUpdate = () => import('@/entities/permission-profile-item-map/permission-profile-item-map-update.vue');
const PermissionProfileItemMapDetails = () => import('@/entities/permission-profile-item-map/permission-profile-item-map-details.vue');

const PermissionProfileMemberMap = () => import('@/entities/permission-profile-member-map/permission-profile-member-map.vue');
const PermissionProfileMemberMapUpdate = () => import('@/entities/permission-profile-member-map/permission-profile-member-map-update.vue');
const PermissionProfileMemberMapDetails = () =>
  import('@/entities/permission-profile-member-map/permission-profile-member-map-details.vue');

const PublicNotice = () => import('@/entities/public-notice/public-notice.vue');
const PublicNoticeUpdate = () => import('@/entities/public-notice/public-notice-update.vue');
const PublicNoticeDetails = () => import('@/entities/public-notice/public-notice-details.vue');

const PublicNoticeFile = () => import('@/entities/public-notice-file/public-notice-file.vue');
const PublicNoticeFileUpdate = () => import('@/entities/public-notice-file/public-notice-file-update.vue');
const PublicNoticeFileDetails = () => import('@/entities/public-notice-file/public-notice-file-details.vue');

const PublicNoticeRead = () => import('@/entities/public-notice-read/public-notice-read.vue');
const PublicNoticeReadUpdate = () => import('@/entities/public-notice-read/public-notice-read-update.vue');
const PublicNoticeReadDetails = () => import('@/entities/public-notice-read/public-notice-read-details.vue');

const Registration = () => import('@/entities/registration/registration.vue');
const RegistrationUpdate = () => import('@/entities/registration/registration-update.vue');
const RegistrationDetails = () => import('@/entities/registration/registration-details.vue');

const SpAppManager = () => import('@/entities/sp-app-manager/sp-app-manager.vue');
const SpAppManagerUpdate = () => import('@/entities/sp-app-manager/sp-app-manager-update.vue');
const SpAppManagerDetails = () => import('@/entities/sp-app-manager/sp-app-manager-details.vue');

const SpClass = () => import('@/entities/sp-class/sp-class.vue');
const SpClassUpdate = () => import('@/entities/sp-class/sp-class-update.vue');
const SpClassDetails = () => import('@/entities/sp-class/sp-class-details.vue');

const SpClassStaff = () => import('@/entities/sp-class-staff/sp-class-staff.vue');
const SpClassStaffUpdate = () => import('@/entities/sp-class-staff/sp-class-staff-update.vue');
const SpClassStaffDetails = () => import('@/entities/sp-class-staff/sp-class-staff-details.vue');

const SpCourse = () => import('@/entities/sp-course/sp-course.vue');
const SpCourseUpdate = () => import('@/entities/sp-course/sp-course-update.vue');
const SpCourseDetails = () => import('@/entities/sp-course/sp-course-details.vue');

const SpLocation = () => import('@/entities/sp-location/sp-location.vue');
const SpLocationUpdate = () => import('@/entities/sp-location/sp-location-update.vue');
const SpLocationDetails = () => import('@/entities/sp-location/sp-location-details.vue');

const SpPaymentOption = () => import('@/entities/sp-payment-option/sp-payment-option.vue');
const SpPaymentOptionUpdate = () => import('@/entities/sp-payment-option/sp-payment-option-update.vue');
const SpPaymentOptionDetails = () => import('@/entities/sp-payment-option/sp-payment-option-details.vue');

const SpRegistration = () => import('@/entities/sp-registration/sp-registration.vue');
const SpRegistrationUpdate = () => import('@/entities/sp-registration/sp-registration-update.vue');
const SpRegistrationDetail = () => import('@/entities/sp-registration/sp-registration-details.vue');

const SpRoom = () => import('@/entities/sp-room/sp-room.vue');
const SpRoomUpdate = () => import('@/entities/sp-room/sp-room-update.vue');
const SpRoomDetails = () => import('@/entities/sp-room/sp-room-details.vue');

const SpRoomAllocation = () => import('@/entities/sp-room-allocation/sp-room-allocation.vue');
const SpRoomAllocationUpdate = () => import('@/entities/sp-room-allocation/sp-room-allocation-update.vue');
const SpRoomAllocationDetails = () => import('@/entities/sp-room-allocation/sp-room-allocation-details.vue');

const SpSession = () => import('@/entities/sp-session/sp-session.vue');
const SpSessionUpdate = () => import('@/entities/sp-session/sp-session-update.vue');
const SpSessionDetails = () => import('@/entities/sp-session/sp-session-details.vue');

const SpecialProgram = () => import('@/entities/special-program/special-program.vue');
const SpecialProgramUpdate = () => import('@/entities/special-program/special-program-update.vue');
const SpecialProgramDetails = () => import('@/entities/special-program/special-program-details.vue');

const StateProvince = () => import('@/entities/state-province/state-province.vue');
const StateProvinceUpdate = () => import('@/entities/state-province/state-province-update.vue');
const StateProvinceDetails = () => import('@/entities/state-province/state-province-details.vue');

const RegistrationPayment = () => import('@/entities/registration-payment/registration-payment.vue');
const RegistrationPaymentUpdate = () => import('@/entities/registration-payment/registration-payment-update.vue');
const RegistrationPaymentDetails = () => import('@/entities/registration-payment/registration-payment-details.vue');

const RegistrationPaymentItem = () => import('@/entities/registration-payment-item/registration-payment-item.vue');
const RegistrationPaymentItemUpdate = () => import('@/entities/registration-payment-item/registration-payment-item-update.vue');
const RegistrationPaymentItemDetails = () => import('@/entities/registration-payment-item/registration-payment-item-details.vue');

const AnnualMembershipFee = () => import('@/entities/annual-membership-fee/annual-membership-fee.vue');
const AnnualMembershipFeeUpdate = () => import('@/entities/annual-membership-fee/annual-membership-fee-update.vue');
const AnnualMembershipFeeDetails = () => import('@/entities/annual-membership-fee/annual-membership-fee-details.vue');

const MemberHelperNote = () => import('@/entities/member-helper-note/member-helper-note.vue');
const MemberHelperNoteUpdate = () => import('@/entities/member-helper-note/member-helper-note-update.vue');
const MemberHelperNoteDetails = () => import('@/entities/member-helper-note/member-helper-note-details.vue');

const DeletedMemberHelperNote = () => import('@/entities/deleted-member-helper-note/deleted-member-helper-note.vue');
const DeletedMemberHelperNoteUpdate = () => import('@/entities/deleted-member-helper-note/deleted-member-helper-note-update.vue');
const DeletedMemberHelperNoteDetails = () => import('@/entities/deleted-member-helper-note/deleted-member-helper-note-details.vue');

const BulkKeycloakAccount = () => import('@/entities/bulk-keycloak-account/bulk-keycloak-account.vue');
const BulkKeycloakAccountUpdate = () => import('@/entities/bulk-keycloak-account/bulk-keycloak-account-update.vue');
const BulkKeycloakAccountDetails = () => import('@/entities/bulk-keycloak-account/bulk-keycloak-account-details.vue');

const MemberStudent = () => import('@/entities/member-student/member-student.vue');
const MemberStudentUpdate = () => import('@/entities/member-student/member-student-update.vue');
const MemberStudentDetails = () => import('@/entities/member-student/member-student-details.vue');

const ProgramYouth = () => import('@/entities/program-youth/program-youth.vue');
const ProgramYouthUpdate = () => import('@/entities/program-youth/program-youth-update.vue');
const ProgramYouthDetails = () => import('@/entities/program-youth/program-youth-details.vue');

const SpClassType = () => import('@/entities/sp-class-type/sp-class-type.vue');
const SpClassTypeUpdate = () => import('@/entities/sp-class-type/sp-class-type-update.vue');
const SpClassTypeDetails = () => import('@/entities/sp-class-type/sp-class-type-details.vue');

const SpNumVideoView = () => import('@/entities/sp-num-video-view/sp-num-video-view.vue');
const SpNumVideoViewUpdate = () => import('@/entities/sp-num-video-view/sp-num-video-view-update.vue');
const SpNumVideoViewDetails = () => import('@/entities/sp-num-video-view/sp-num-video-view-details.vue');

const SpRegistrationDetails = () => import('@/entities/sp-registration-details/sp-registration-details.vue');
const SpRegistrationDetailsUpdate = () => import('@/entities/sp-registration-details/sp-registration-details-update.vue');
const SpRegistrationDetailsDetails = () => import('@/entities/sp-registration-details/sp-registration-details-details.vue');

const SpTransport = () => import('@/entities/sp-transport/sp-transport.vue');
const SpTransportUpdate = () => import('@/entities/sp-transport/sp-transport-update.vue');
const SpTransportDetails = () => import('@/entities/sp-transport/sp-transport-details.vue');

const SpTransportRoute = () => import('@/entities/sp-transport-route/sp-transport-route.vue');
const SpTransportRouteUpdate = () => import('@/entities/sp-transport-route/sp-transport-route-update.vue');
const SpTransportRouteDetails = () => import('@/entities/sp-transport-route/sp-transport-route-details.vue');

const SpTransportBoardingInfo = () => import('@/entities/sp-transport-boarding-info/sp-transport-boarding-info.vue');
const SpTransportBoardingInfoUpdate = () => import('@/entities/sp-transport-boarding-info/sp-transport-boarding-info-update.vue');
const SpTransportBoardingInfoDetails = () => import('@/entities/sp-transport-boarding-info/sp-transport-boarding-info-details.vue');

const SpRegistrationRequest = () => import('@/entities/sp-registration-request/sp-registration-request.vue');
const SpRegistrationRequestUpdate = () => import('@/entities/sp-registration-request/sp-registration-request-update.vue');
const SpRegistrationRequestDetails = () => import('@/entities/sp-registration-request/sp-registration-request-details.vue');

// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'address',
      name: 'Address',
      component: Address,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'address/new',
      name: 'AddressCreate',
      component: AddressUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'address/:addressId/edit',
      name: 'AddressEdit',
      component: AddressUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'address/:addressId/view',
      name: 'AddressView',
      component: AddressDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'attendance',
      name: 'Attendance',
      component: Attendance,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'attendance/new',
      name: 'AttendanceCreate',
      component: AttendanceUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'attendance/:attendanceId/edit',
      name: 'AttendanceEdit',
      component: AttendanceUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'attendance/:attendanceId/view',
      name: 'AttendanceView',
      component: AttendanceDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'deleted-attendance',
      name: 'DeletedAttendance',
      component: DeletedAttendance,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'deleted-attendance/new',
      name: 'DeletedAttendanceCreate',
      component: DeletedAttendanceUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'deleted-attendance/:deletedAttendanceId/edit',
      name: 'DeletedAttendanceEdit',
      component: DeletedAttendanceUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'deleted-attendance/:deletedAttendanceId/view',
      name: 'DeletedAttendanceView',
      component: DeletedAttendanceDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'center',
      name: 'Center',
      component: Center,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'center/new',
      name: 'CenterCreate',
      component: CenterUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'center/:centerId/edit',
      name: 'CenterEdit',
      component: CenterUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'center/:centerId/view',
      name: 'CenterView',
      component: CenterDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'center-group',
      name: 'CenterGroup',
      component: CenterGroup,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'center-group/new',
      name: 'CenterGroupCreate',
      component: CenterGroupUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'center-group/:centerGroupId/edit',
      name: 'CenterGroupEdit',
      component: CenterGroupUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'center-group/:centerGroupId/view',
      name: 'CenterGroupView',
      component: CenterGroupDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'center-request-token',
      name: 'CenterRequestToken',
      component: CenterRequestToken,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'center-request-token/new',
      name: 'CenterRequestTokenCreate',
      component: CenterRequestTokenUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'center-request-token/:centerRequestTokenId/edit',
      name: 'CenterRequestTokenEdit',
      component: CenterRequestTokenUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'center-request-token/:centerRequestTokenId/view',
      name: 'CenterRequestTokenView',
      component: CenterRequestTokenDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'center-token',
      name: 'CenterToken',
      component: CenterToken,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'center-token/new',
      name: 'CenterTokenCreate',
      component: CenterTokenUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'center-token/:centerTokenId/edit',
      name: 'CenterTokenEdit',
      component: CenterTokenUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'center-token/:centerTokenId/view',
      name: 'CenterTokenView',
      component: CenterTokenDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'company',
      name: 'Company',
      component: Company,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'company/new',
      name: 'CompanyCreate',
      component: CompanyUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'company/:companyId/edit',
      name: 'CompanyEdit',
      component: CompanyUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'company/:companyId/view',
      name: 'CompanyView',
      component: CompanyDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'country',
      name: 'Country',
      component: Country,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'country/new',
      name: 'CountryCreate',
      component: CountryUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'country/:countryId/edit',
      name: 'CountryEdit',
      component: CountryUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'country/:countryId/view',
      name: 'CountryView',
      component: CountryDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'deleted-member',
      name: 'DeletedMember',
      component: DeletedMember,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'deleted-member/new',
      name: 'DeletedMemberCreate',
      component: DeletedMemberUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'deleted-member/:deletedMemberId/edit',
      name: 'DeletedMemberEdit',
      component: DeletedMemberUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'deleted-member/:deletedMemberId/view',
      name: 'DeletedMemberView',
      component: DeletedMemberDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'employee',
      name: 'Employee',
      component: Employee,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'employee/new',
      name: 'EmployeeCreate',
      component: EmployeeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'employee/:employeeId/edit',
      name: 'EmployeeEdit',
      component: EmployeeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'employee/:employeeId/view',
      name: 'EmployeeView',
      component: EmployeeDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'employee-history',
      name: 'EmployeeHistory',
      component: EmployeeHistory,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'employee-history/new',
      name: 'EmployeeHistoryCreate',
      component: EmployeeHistoryUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'employee-history/:employeeHistoryId/edit',
      name: 'EmployeeHistoryEdit',
      component: EmployeeHistoryUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'employee-history/:employeeHistoryId/view',
      name: 'EmployeeHistoryView',
      component: EmployeeHistoryDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'file-token',
      name: 'FileToken',
      component: FileToken,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'file-token/new',
      name: 'FileTokenCreate',
      component: FileTokenUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'file-token/:fileTokenId/edit',
      name: 'FileTokenEdit',
      component: FileTokenUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'file-token/:fileTokenId/view',
      name: 'FileTokenView',
      component: FileTokenDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'helper',
      name: 'Helper',
      component: Helper,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'helper/new',
      name: 'HelperCreate',
      component: HelperUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'helper/:helperId/edit',
      name: 'HelperEdit',
      component: HelperUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'helper/:helperId/view',
      name: 'HelperView',
      component: HelperDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'helper-history',
      name: 'HelperHistory',
      component: HelperHistory,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'helper-history/new',
      name: 'HelperHistoryCreate',
      component: HelperHistoryUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'helper-history/:helperHistoryId/edit',
      name: 'HelperHistoryEdit',
      component: HelperHistoryUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'helper-history/:helperHistoryId/view',
      name: 'HelperHistoryView',
      component: HelperHistoryDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'level',
      name: 'Level',
      component: Level,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'level/new',
      name: 'LevelCreate',
      component: LevelUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'level/:levelId/edit',
      name: 'LevelEdit',
      component: LevelUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'level/:levelId/view',
      name: 'LevelView',
      component: LevelDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'level-progress',
      name: 'LevelProgress',
      component: LevelProgress,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'level-progress/new',
      name: 'LevelProgressCreate',
      component: LevelProgressUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'level-progress/:levelProgressId/edit',
      name: 'LevelProgressEdit',
      component: LevelProgressUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'level-progress/:levelProgressId/view',
      name: 'LevelProgressView',
      component: LevelProgressDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'deleted-level-progress',
      name: 'DeletedLevelProgress',
      component: DeletedLevelProgress,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'deleted-level-progress/new',
      name: 'DeletedLevelProgressCreate',
      component: DeletedLevelProgressUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'deleted-level-progress/:deletedLevelProgressId/edit',
      name: 'DeletedLevelProgressEdit',
      component: DeletedLevelProgressUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'deleted-level-progress/:deletedLevelProgressId/view',
      name: 'DeletedLevelProgressView',
      component: DeletedLevelProgressDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member',
      name: 'Member',
      component: Member,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member/new',
      name: 'MemberCreate',
      component: MemberUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member/:memberId/edit',
      name: 'MemberEdit',
      component: MemberUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member/:memberId/view',
      name: 'MemberView',
      component: MemberDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member-information',
      name: 'MemberInformation',
      component: MemberInformation,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member-information/new',
      name: 'MemberInformationCreate',
      component: MemberInformationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member-information/:memberInformationId/edit',
      name: 'MemberInformationEdit',
      component: MemberInformationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member-information/:memberInformationId/view',
      name: 'MemberInformationView',
      component: MemberInformationDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member-career',
      name: 'MemberCareer',
      component: MemberCareer,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member-career/new',
      name: 'MemberCareerCreate',
      component: MemberCareerUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member-career/:memberCareerId/edit',
      name: 'MemberCareerEdit',
      component: MemberCareerUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member-career/:memberCareerId/view',
      name: 'MemberCareerView',
      component: MemberCareerDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member-history',
      name: 'MemberHistory',
      component: MemberHistory,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member-history/new',
      name: 'MemberHistoryCreate',
      component: MemberHistoryUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member-history/:memberHistoryId/edit',
      name: 'MemberHistoryEdit',
      component: MemberHistoryUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member-history/:memberHistoryId/view',
      name: 'MemberHistoryView',
      component: MemberHistoryDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member-logging',
      name: 'MemberLogging',
      component: MemberLogging,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member-logging/new',
      name: 'MemberLoggingCreate',
      component: MemberLoggingUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member-logging/:memberLoggingId/edit',
      name: 'MemberLoggingEdit',
      component: MemberLoggingUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member-logging/:memberLoggingId/view',
      name: 'MemberLoggingView',
      component: MemberLoggingDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member-relation',
      name: 'MemberRelation',
      component: MemberRelation,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member-relation/new',
      name: 'MemberRelationCreate',
      component: MemberRelationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member-relation/:memberRelationId/edit',
      name: 'MemberRelationEdit',
      component: MemberRelationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member-relation/:memberRelationId/view',
      name: 'MemberRelationView',
      component: MemberRelationDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member-schedule',
      name: 'MemberSchedule',
      component: MemberSchedule,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member-schedule/new',
      name: 'MemberScheduleCreate',
      component: MemberScheduleUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member-schedule/:memberScheduleId/edit',
      name: 'MemberScheduleEdit',
      component: MemberScheduleUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member-schedule/:memberScheduleId/view',
      name: 'MemberScheduleView',
      component: MemberScheduleDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member-vehicle',
      name: 'MemberVehicle',
      component: MemberVehicle,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member-vehicle/new',
      name: 'MemberVehicleCreate',
      component: MemberVehicleUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member-vehicle/:memberVehicleId/edit',
      name: 'MemberVehicleEdit',
      component: MemberVehicleUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member-vehicle/:memberVehicleId/view',
      name: 'MemberVehicleView',
      component: MemberVehicleDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'notification',
      name: 'Notification',
      component: Notification,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'notification/new',
      name: 'NotificationCreate',
      component: NotificationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'notification/:notificationId/edit',
      name: 'NotificationEdit',
      component: NotificationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'notification/:notificationId/view',
      name: 'NotificationView',
      component: NotificationDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'organization',
      name: 'Organization',
      component: Organization,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'organization/new',
      name: 'OrganizationCreate',
      component: OrganizationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'organization/:organizationId/edit',
      name: 'OrganizationEdit',
      component: OrganizationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'organization/:organizationId/view',
      name: 'OrganizationView',
      component: OrganizationDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'permission-item',
      name: 'PermissionItem',
      component: PermissionItem,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'permission-item/new',
      name: 'PermissionItemCreate',
      component: PermissionItemUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'permission-item/:permissionItemId/edit',
      name: 'PermissionItemEdit',
      component: PermissionItemUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'permission-item/:permissionItemId/view',
      name: 'PermissionItemView',
      component: PermissionItemDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'permission-profile',
      name: 'PermissionProfile',
      component: PermissionProfile,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'permission-profile/new',
      name: 'PermissionProfileCreate',
      component: PermissionProfileUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'permission-profile/:permissionProfileId/edit',
      name: 'PermissionProfileEdit',
      component: PermissionProfileUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'permission-profile/:permissionProfileId/view',
      name: 'PermissionProfileView',
      component: PermissionProfileDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'permission-profile-item-map',
      name: 'PermissionProfileItemMap',
      component: PermissionProfileItemMap,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'permission-profile-item-map/new',
      name: 'PermissionProfileItemMapCreate',
      component: PermissionProfileItemMapUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'permission-profile-item-map/:permissionProfileItemMapId/edit',
      name: 'PermissionProfileItemMapEdit',
      component: PermissionProfileItemMapUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'permission-profile-item-map/:permissionProfileItemMapId/view',
      name: 'PermissionProfileItemMapView',
      component: PermissionProfileItemMapDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'permission-profile-member-map',
      name: 'PermissionProfileMemberMap',
      component: PermissionProfileMemberMap,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'permission-profile-member-map/new',
      name: 'PermissionProfileMemberMapCreate',
      component: PermissionProfileMemberMapUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'permission-profile-member-map/:permissionProfileMemberMapId/edit',
      name: 'PermissionProfileMemberMapEdit',
      component: PermissionProfileMemberMapUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'permission-profile-member-map/:permissionProfileMemberMapId/view',
      name: 'PermissionProfileMemberMapView',
      component: PermissionProfileMemberMapDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'public-notice',
      name: 'PublicNotice',
      component: PublicNotice,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'public-notice/new',
      name: 'PublicNoticeCreate',
      component: PublicNoticeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'public-notice/:publicNoticeId/edit',
      name: 'PublicNoticeEdit',
      component: PublicNoticeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'public-notice/:publicNoticeId/view',
      name: 'PublicNoticeView',
      component: PublicNoticeDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'public-notice-file',
      name: 'PublicNoticeFile',
      component: PublicNoticeFile,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'public-notice-file/new',
      name: 'PublicNoticeFileCreate',
      component: PublicNoticeFileUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'public-notice-file/:publicNoticeFileId/edit',
      name: 'PublicNoticeFileEdit',
      component: PublicNoticeFileUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'public-notice-file/:publicNoticeFileId/view',
      name: 'PublicNoticeFileView',
      component: PublicNoticeFileDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'public-notice-read',
      name: 'PublicNoticeRead',
      component: PublicNoticeRead,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'public-notice-read/new',
      name: 'PublicNoticeReadCreate',
      component: PublicNoticeReadUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'public-notice-read/:publicNoticeReadId/edit',
      name: 'PublicNoticeReadEdit',
      component: PublicNoticeReadUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'public-notice-read/:publicNoticeReadId/view',
      name: 'PublicNoticeReadView',
      component: PublicNoticeReadDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'registration',
      name: 'Registration',
      component: Registration,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'registration/new',
      name: 'RegistrationCreate',
      component: RegistrationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'registration/:registrationId/edit',
      name: 'RegistrationEdit',
      component: RegistrationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'registration/:registrationId/view',
      name: 'RegistrationView',
      component: RegistrationDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-app-manager',
      name: 'SpAppManager',
      component: SpAppManager,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-app-manager/new',
      name: 'SpAppManagerCreate',
      component: SpAppManagerUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-app-manager/:spAppManagerId/edit',
      name: 'SpAppManagerEdit',
      component: SpAppManagerUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-app-manager/:spAppManagerId/view',
      name: 'SpAppManagerView',
      component: SpAppManagerDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-class',
      name: 'SpClass',
      component: SpClass,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-class/new',
      name: 'SpClassCreate',
      component: SpClassUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-class/:spClassId/edit',
      name: 'SpClassEdit',
      component: SpClassUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-class/:spClassId/view',
      name: 'SpClassView',
      component: SpClassDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-class-staff',
      name: 'SpClassStaff',
      component: SpClassStaff,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-class-staff/new',
      name: 'SpClassStaffCreate',
      component: SpClassStaffUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-class-staff/:spClassStaffId/edit',
      name: 'SpClassStaffEdit',
      component: SpClassStaffUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-class-staff/:spClassStaffId/view',
      name: 'SpClassStaffView',
      component: SpClassStaffDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-course',
      name: 'SpCourse',
      component: SpCourse,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-course/new',
      name: 'SpCourseCreate',
      component: SpCourseUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-course/:spCourseId/edit',
      name: 'SpCourseEdit',
      component: SpCourseUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-course/:spCourseId/view',
      name: 'SpCourseView',
      component: SpCourseDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-location',
      name: 'SpLocation',
      component: SpLocation,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-location/new',
      name: 'SpLocationCreate',
      component: SpLocationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-location/:spLocationId/edit',
      name: 'SpLocationEdit',
      component: SpLocationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-location/:spLocationId/view',
      name: 'SpLocationView',
      component: SpLocationDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-payment-option',
      name: 'SpPaymentOption',
      component: SpPaymentOption,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-payment-option/new',
      name: 'SpPaymentOptionCreate',
      component: SpPaymentOptionUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-payment-option/:spPaymentOptionId/edit',
      name: 'SpPaymentOptionEdit',
      component: SpPaymentOptionUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-payment-option/:spPaymentOptionId/view',
      name: 'SpPaymentOptionView',
      component: SpPaymentOptionDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-registration',
      name: 'SpRegistration',
      component: SpRegistration,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-registration/new',
      name: 'SpRegistrationCreate',
      component: SpRegistrationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-registration/:spRegistrationId/edit',
      name: 'SpRegistrationEdit',
      component: SpRegistrationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-registration/:spRegistrationId/view',
      name: 'SpRegistrationView',
      component: SpRegistrationDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-room',
      name: 'SpRoom',
      component: SpRoom,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-room/new',
      name: 'SpRoomCreate',
      component: SpRoomUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-room/:spRoomId/edit',
      name: 'SpRoomEdit',
      component: SpRoomUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-room/:spRoomId/view',
      name: 'SpRoomView',
      component: SpRoomDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-room-allocation',
      name: 'SpRoomAllocation',
      component: SpRoomAllocation,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-room-allocation/new',
      name: 'SpRoomAllocationCreate',
      component: SpRoomAllocationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-room-allocation/:spRoomAllocationId/edit',
      name: 'SpRoomAllocationEdit',
      component: SpRoomAllocationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-room-allocation/:spRoomAllocationId/view',
      name: 'SpRoomAllocationView',
      component: SpRoomAllocationDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-session',
      name: 'SpSession',
      component: SpSession,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-session/new',
      name: 'SpSessionCreate',
      component: SpSessionUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-session/:spSessionId/edit',
      name: 'SpSessionEdit',
      component: SpSessionUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-session/:spSessionId/view',
      name: 'SpSessionView',
      component: SpSessionDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'special-program',
      name: 'SpecialProgram',
      component: SpecialProgram,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'special-program/new',
      name: 'SpecialProgramCreate',
      component: SpecialProgramUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'special-program/:specialProgramId/edit',
      name: 'SpecialProgramEdit',
      component: SpecialProgramUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'special-program/:specialProgramId/view',
      name: 'SpecialProgramView',
      component: SpecialProgramDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'state-province',
      name: 'StateProvince',
      component: StateProvince,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'state-province/new',
      name: 'StateProvinceCreate',
      component: StateProvinceUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'state-province/:stateProvinceId/edit',
      name: 'StateProvinceEdit',
      component: StateProvinceUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'state-province/:stateProvinceId/view',
      name: 'StateProvinceView',
      component: StateProvinceDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'registration-payment',
      name: 'RegistrationPayment',
      component: RegistrationPayment,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'registration-payment/new',
      name: 'RegistrationPaymentCreate',
      component: RegistrationPaymentUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'registration-payment/:registrationPaymentId/edit',
      name: 'RegistrationPaymentEdit',
      component: RegistrationPaymentUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'registration-payment/:registrationPaymentId/view',
      name: 'RegistrationPaymentView',
      component: RegistrationPaymentDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'registration-payment-item',
      name: 'RegistrationPaymentItem',
      component: RegistrationPaymentItem,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'registration-payment-item/new',
      name: 'RegistrationPaymentItemCreate',
      component: RegistrationPaymentItemUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'registration-payment-item/:registrationPaymentItemId/edit',
      name: 'RegistrationPaymentItemEdit',
      component: RegistrationPaymentItemUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'registration-payment-item/:registrationPaymentItemId/view',
      name: 'RegistrationPaymentItemView',
      component: RegistrationPaymentItemDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'annual-membership-fee',
      name: 'AnnualMembershipFee',
      component: AnnualMembershipFee,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'annual-membership-fee/new',
      name: 'AnnualMembershipFeeCreate',
      component: AnnualMembershipFeeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'annual-membership-fee/:annualMembershipFeeId/edit',
      name: 'AnnualMembershipFeeEdit',
      component: AnnualMembershipFeeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'annual-membership-fee/:annualMembershipFeeId/view',
      name: 'AnnualMembershipFeeView',
      component: AnnualMembershipFeeDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member-helper-note',
      name: 'MemberHelperNote',
      component: MemberHelperNote,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member-helper-note/new',
      name: 'MemberHelperNoteCreate',
      component: MemberHelperNoteUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member-helper-note/:memberHelperNoteId/edit',
      name: 'MemberHelperNoteEdit',
      component: MemberHelperNoteUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member-helper-note/:memberHelperNoteId/view',
      name: 'MemberHelperNoteView',
      component: MemberHelperNoteDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'deleted-member-helper-note',
      name: 'DeletedMemberHelperNote',
      component: DeletedMemberHelperNote,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'deleted-member-helper-note/new',
      name: 'DeletedMemberHelperNoteCreate',
      component: DeletedMemberHelperNoteUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'deleted-member-helper-note/:deletedMemberHelperNoteId/edit',
      name: 'DeletedMemberHelperNoteEdit',
      component: DeletedMemberHelperNoteUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'deleted-member-helper-note/:deletedMemberHelperNoteId/view',
      name: 'DeletedMemberHelperNoteView',
      component: DeletedMemberHelperNoteDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bulk-keycloak-account',
      name: 'BulkKeycloakAccount',
      component: BulkKeycloakAccount,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bulk-keycloak-account/new',
      name: 'BulkKeycloakAccountCreate',
      component: BulkKeycloakAccountUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bulk-keycloak-account/:bulkKeycloakAccountId/edit',
      name: 'BulkKeycloakAccountEdit',
      component: BulkKeycloakAccountUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bulk-keycloak-account/:bulkKeycloakAccountId/view',
      name: 'BulkKeycloakAccountView',
      component: BulkKeycloakAccountDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member-student',
      name: 'MemberStudent',
      component: MemberStudent,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member-student/new',
      name: 'MemberStudentCreate',
      component: MemberStudentUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member-student/:memberStudentId/edit',
      name: 'MemberStudentEdit',
      component: MemberStudentUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'member-student/:memberStudentId/view',
      name: 'MemberStudentView',
      component: MemberStudentDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'program-youth',
      name: 'ProgramYouth',
      component: ProgramYouth,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'program-youth/new',
      name: 'ProgramYouthCreate',
      component: ProgramYouthUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'program-youth/:programYouthId/edit',
      name: 'ProgramYouthEdit',
      component: ProgramYouthUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'program-youth/:programYouthId/view',
      name: 'ProgramYouthView',
      component: ProgramYouthDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-class-type',
      name: 'SpClassType',
      component: SpClassType,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-class-type/new',
      name: 'SpClassTypeCreate',
      component: SpClassTypeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-class-type/:spClassTypeId/edit',
      name: 'SpClassTypeEdit',
      component: SpClassTypeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-class-type/:spClassTypeId/view',
      name: 'SpClassTypeView',
      component: SpClassTypeDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-num-video-view',
      name: 'SpNumVideoView',
      component: SpNumVideoView,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-num-video-view/new',
      name: 'SpNumVideoViewCreate',
      component: SpNumVideoViewUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-num-video-view/:spNumVideoViewId/edit',
      name: 'SpNumVideoViewEdit',
      component: SpNumVideoViewUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-num-video-view/:spNumVideoViewId/view',
      name: 'SpNumVideoViewView',
      component: SpNumVideoViewDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-registration-details',
      name: 'SpRegistrationDetails',
      component: SpRegistrationDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-registration-details/new',
      name: 'SpRegistrationDetailsCreate',
      component: SpRegistrationDetailsUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-registration-details/:spRegistrationDetailsId/edit',
      name: 'SpRegistrationDetailsEdit',
      component: SpRegistrationDetailsUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-registration-details/:spRegistrationDetailsId/view',
      name: 'SpRegistrationDetailsView',
      component: SpRegistrationDetailsDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-transport',
      name: 'SpTransport',
      component: SpTransport,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-transport/new',
      name: 'SpTransportCreate',
      component: SpTransportUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-transport/:spTransportId/edit',
      name: 'SpTransportEdit',
      component: SpTransportUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-transport/:spTransportId/view',
      name: 'SpTransportView',
      component: SpTransportDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-transport-route',
      name: 'SpTransportRoute',
      component: SpTransportRoute,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-transport-route/new',
      name: 'SpTransportRouteCreate',
      component: SpTransportRouteUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-transport-route/:spTransportRouteId/edit',
      name: 'SpTransportRouteEdit',
      component: SpTransportRouteUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-transport-route/:spTransportRouteId/view',
      name: 'SpTransportRouteView',
      component: SpTransportRouteDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-transport-boarding-info',
      name: 'SpTransportBoardingInfo',
      component: SpTransportBoardingInfo,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-transport-boarding-info/new',
      name: 'SpTransportBoardingInfoCreate',
      component: SpTransportBoardingInfoUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-transport-boarding-info/:spTransportBoardingInfoId/edit',
      name: 'SpTransportBoardingInfoEdit',
      component: SpTransportBoardingInfoUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-transport-boarding-info/:spTransportBoardingInfoId/view',
      name: 'SpTransportBoardingInfoView',
      component: SpTransportBoardingInfoDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-registration-request',
      name: 'SpRegistrationRequest',
      component: SpRegistrationRequest,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-registration-request/new',
      name: 'SpRegistrationRequestCreate',
      component: SpRegistrationRequestUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-registration-request/:spRegistrationRequestId/edit',
      name: 'SpRegistrationRequestEdit',
      component: SpRegistrationRequestUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sp-registration-request/:spRegistrationRequestId/view',
      name: 'SpRegistrationRequestView',
      component: SpRegistrationRequestDetails,
      meta: { authorities: [Authority.USER] },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  ],
};
